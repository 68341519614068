import React, { useState, useEffect} from 'react';
import ScheduleSlotPicker from './ScheduleSlotPicker';
import ScheduleSpeakerBlock from './ScheduleSpeakerBlock';
import SpeakerTalkModal from './SpeakerTalkModal';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

const Schedule = () => {
    const speakerData = useStaticQuery(graphql`
      query speakerScheduleDataQuery {
        sanitySpeakers {
          speakers {
            firstName
            lastName
            jobTitle
            talkTitle,
            slot,
            track,
            bio {
              children {
                text
              }
            },
            talkDescription {
              children {
                text
              }
            },
            speakerImage {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `);

    const [slots] = useState([
      {number:"Opening keynote", start: "9:15", end:"10:00", displayValue: "KEYNOTE"},
      {number:"1", start: "10:10", end:"11:10", displayValue: "Breakout"},
      {number:"2", start: "11:30", end:"11:50", displayValue: "Breakout"},
      {number:"3", start: "12:00", end:"12:20", displayValue: "Breakout"},
      {number:"4", start: "13:30", end:"14:30", displayValue: "Breakout"},
      {number:"5", start: "14:40", end:"15:00", displayValue: "Breakout"},
      {number:"6", start: "15:20", end:"16:20", displayValue: "Breakout"},
      {number:"7", start: "16:30", end:"16:50", displayValue: "Breakout"},
      {number:"Closing keynote", start: "17:00", end:"17:45", displayValue: "KEYNOTE"},
    ]);

    const [selectedSlot, SetSelectedSlot] = useState((slots.filter(x => x.number === "Closing keynote")[0]));
    const [speakersForSelectedSlot, SetSpeakersForSelectedSlot] = useState([]);
    const [selectedSpeakerTalk, setSelectedSpeakerTalk] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getBodyTag = () => document.getElementsByTagName("body")[0];

    const location = useLocation();

    const onSelectSpeakerTalk = (speaker) => {
      setSelectedSpeakerTalk(speaker);
      setIsModalOpen(true);
      getBodyTag().classList.add("modal-open");
    }

    const onCloseModal = () => {
      setIsModalOpen(false);
      getBodyTag().classList.remove("modal-open");
    }

    const filterSpeakersForSelectedSlot = (slot) => {
      var speakers = speakerData.sanitySpeakers.speakers.filter(speaker => speaker.slot === slot.number);
     
      SetSpeakersForSelectedSlot(speakers);
      SetSelectedSlot(slot);
    };

    const removeSpecialCharactersName = (name) => name.trim().replace(/[^a-zA-Z0-9]/g, '');

    const openTalkFromQueryString = (urlQueryString) => {
      const query = queryString.parse(urlQueryString.toLowerCase());

      speakerData.sanitySpeakers.speakers.map(speaker => {console.log(removeSpecialCharactersName(speaker.lastName.toLowerCase()))});

      var selectedSpeaker = speakerData.sanitySpeakers.speakers.filter(speaker => removeSpecialCharactersName(speaker.firstName.toLowerCase())  === query.firstname.trim() 
        && removeSpecialCharactersName(speaker.lastName.toLowerCase()) === query.lastname.trim())[0];
    
      if(selectedSpeaker === undefined) {
        // no speaker found
        filterSpeakersForSelectedSlot(selectedSlot);
      } else {
        // speaker found
        var slot = slots.filter(slot => slot.number === selectedSpeaker.slot)[0];
        filterSpeakersForSelectedSlot(slot);
        onSelectSpeakerTalk(selectedSpeaker);
      }
    };

    useEffect(() => {
      if(location.search.toLowerCase().indexOf("firstname") !== -1 && location.search.toLowerCase().indexOf("lastname") !== -1 ) {
        openTalkFromQueryString(location.search);
      } else {
        filterSpeakersForSelectedSlot(selectedSlot);
      }
    }, []);

    return (
      <>
        <div id="schedule-speaker-blocks">
          <div className="container">
{/*            <div className="row">
              <div className="col">
                  <h4 className="mt-5 text-center">Livestream starts at 1PM CEST</h4>
              </div>
            </div>*/}
            <div className="row">
              <div className="col">
                <ScheduleSlotPicker slots={slots}
                  onSlotSelected={filterSpeakersForSelectedSlot}
                  selectedSlot={selectedSlot}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                {speakersForSelectedSlot
                  .map((speaker, index) => (
                    <ScheduleSpeakerBlock onSelectSpeakerTalk={onSelectSpeakerTalk} key={index} speaker={speaker} />
                  ))}
              </div>
            </div>
          </div>
        </div>
        {selectedSpeakerTalk !== undefined && <SpeakerTalkModal onClose={onCloseModal} start={selectedSlot.start} speaker={selectedSpeakerTalk} show={isModalOpen} />}
      </>
    );
};

export default Schedule;
