import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/Seo';
import Schedule from '../components/schedule/Schedule';
import NavBar from "../components/navbar/NavBar";

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Schedule" />
            <NavBar />
            <Schedule/>
            <div className="filler"></div>
        </Layout>
    );
};

export default IndexPage;
