import React from 'react';
import MobileSlotDropdown from './MobileSlotDropDown';
import Slot from './Slot';

const ScheduleSlotPicker = ({onSlotSelected, slots, selectedSlot}) => {
    const changeSelectedSlot = (slotNumber) => {
        var slot = slots.filter(x => x.number === slotNumber)[0];
        onSlotSelected(slot);
    };

    return (
        <>
            <div className="d-none d-lg-flex justify-content-center mt-md-5 mx-md-5">
                {slots.map((slot, index) => <Slot key={index} name={slot.displayValue} slotClass={"p-2 " + (index !==8 ? "mr-5 ": "") + (selectedSlot.number === slot.number ? "active": "")} onSlotSelected={changeSelectedSlot} number={slot.number} start={slot.start} end={slot.end}  />)}
            </div>
            <div className="d-lg-none ">
                <MobileSlotDropdown onSlotSelected={changeSelectedSlot} selectedSlot={selectedSlot} slots={slots} />
            </div>
        </>
    )
};

export default ScheduleSlotPicker;