import React from 'react';
import './slot.scss';

const Slot = ({ name, number, start, end, slotClass, onSlotSelected }) => {
    return (
        <div
            onClick={() => onSlotSelected(number)}
            className={
                'slot d-flex flex-column align-items-center ' + slotClass
            }
        >
            <div className="slot-title">{name}</div>
            <div className="slot-time">
                {start} - {end}
            </div>
            <div className="slot-timezone">CEST</div>
        </div>
    );
};

export default Slot;
