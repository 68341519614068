import Img from 'gatsby-image';
import React from 'react';
import CloseIcon from '../../images/x.svg';
import './speakerTalkModal.scss';

const SpeakerTalkModal = ({ speaker, start, show, onClose }) => {
    const getImage = () => {
        return (
            <Img
                className="speaker-image mr-lg-5"
                fluid={speaker.speakerImage.asset.fluid}
            />
        );
    };

    return (
        <div
            className={'modal fade ' + (show === true ? 'show d-block' : '')}
            tabIndex="-1"
            aria-hidden="true"
        >
            <div
                id="speaker-talk-modal"
                className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            >
                <div className="modal-content">
                    <img
                        onClick={() => onClose()}
                        id="modal-close"
                        src={CloseIcon}
                        title="close"
                        alt="close"
                    />
                    <div className="modal-body">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                                <div className="speaker-info">
                                    <h2 className="font-weight-bold mt-2">
                                        {speaker.talkTitle}
                                    </h2>
                                    <h4 className="mb-0 speaker-name">
                                        <span className="highlight">
                                            {speaker.firstName}
                                        </span>{' '}
                                        {speaker.lastName}
                                    </h4>
                                    <div className="mt-0">
                                        {speaker.jobTitle}
                                    </div>
                                </div>
                                <div className="filler d-none d-md-block"></div>
                                <div className="d-flex align-items-center justify-content-between mt-5 mt-lg-0">
                                    <h4 className="start-time mb-0 d-none d-lg-block mr-5">
                                        {start} CEST
                                    </h4>
                                    <div className="speaker-track d-flex flex-column align-items-center">
                                        <h6 className="mb-0 w-100 d-lg-none room">ROOM</h6>
                                        <h5 className="mb-0 w-100 d-none d-lg-block room">ROOM</h5>
                                        <h5 className="mb-0 d-lg-none">
                                            {speaker.track}
                                        </h5>
                                        <h3 className="mb-0 d-none d-lg-block">
                                            {speaker.track}
                                        </h3>
                                    </div>
                                    <h3 className="start-time mb-0 d-lg-none">
                                        {start} CEST
                                    </h3>
                                </div>
                            </div>

                            <div className="mt-3">
                                {speaker.talkDescription?.map((item, index) => {
                                    return (
                                        <div
                                            className="speaker-talk-description mb-3"
                                            key={index}
                                        >
                                            {item.children.map(
                                                (childItem, childIndex) => {
                                                    return childItem.text;
                                                }
                                            )}

                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="d-flex justify-content-center mt-5">
                                {getImage()}
                            </div>
                            <h5 className="mt-3 mb-0">
                                About{' '}
                                <span className="highlight">
                                    {speaker.firstName} {speaker.lastName}
                                </span>
                            </h5>
                            <div className="mb-3">
                                {speaker.jobTitle}
                            </div>
                            <div className="mb-3">
                                {speaker.bio.map((item, index) => {
                                    return (
                                        <div
                                            className="speaker-bio mb-3"
                                            key={index}
                                        >
                                            {item.children.map(
                                                (childItem, childIndex) => {
                                                    return childItem.text.replace('&nbsp;', '');
                                                }
                                            )}

                                            <br />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpeakerTalkModal;
