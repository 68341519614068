import React, { useState } from 'react';
import DownIcon from '../../images/chevron-down.svg';
import './mobileSlotDropdown.scss';

const MobileSlotDropdown = ({ slots, selectedSlot, onSlotSelected }) => {
    const [showDropDown, setShowDropDown] = useState(false);

    const onClickDropDown = () => {
        setShowDropDown(!showDropDown);
    };

    const onSelectSlot = (number) => {
        onSlotSelected(number);
        setShowDropDown(false);
    };

    return (
        <div id="mobile-slot-dropdown" className="dropdown my-3">
            <div
                className="mobile-slot-dropdown py-3 px-4 d-flex justify-content-between align-items-center"
                onClick={onClickDropDown}
            >
                <div>
                    <MobileSlotDropdownItem
                        number={selectedSlot.number}
                        name={selectedSlot.displayValue}
                        start={selectedSlot.start}
                        end={selectedSlot.end}
                    />
                </div>
                <span>
                    <img src={DownIcon} alt="down-icon" />
                </span>
            </div>
            <ul
                className={
                    'dropdown-menu py-3 px-4 ' +
                    (showDropDown === true ? 'show' : '')
                }
            >
                {slots.map((slot, index) => (
                    <li className="my-2" key={index}>
                        <MobileSlotDropdownItem
                            onSlotSelected={onSelectSlot}
                            key={index}
                            number={slot.number}
                            name={slot.displayValue}
                            start={slot.start}
                            end={slot.end}
                        />{' '}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const MobileSlotDropdownItem = ({
    number,
    name,
    start,
    end,
    onSlotSelected,
}) => {
    return (
        <>
            <div onClick={() => onSlotSelected(number)}>
                <div className="mobile-slot-title">{name}</div>
                <div className="mobile-slot-time">
                    {start} - {end} CEST
                </div>
            </div>
        </>
    );
};

export default MobileSlotDropdown;
