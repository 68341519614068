import React from 'react';
import Img from "gatsby-image"
import "./scheduleSpeakerBlock.scss";

const ScheduleSpeakerBlock = ({speaker, onSelectSpeakerTalk}) => {
    const getImage = (classText) => {
        return (<Img className={"speaker-image mr-md-4 mr-lg-5 " + classText} fluid={speaker.speakerImage?.asset.fluid} />);
    }

    return (
      <>
        <div
          tabIndex="1"
          onClick={() => onSelectSpeakerTalk(speaker)}
          className="my-3 m-md-5 speaker-block d-flex align-items-center p-4"
        >
          {getImage("d-none d-sm-block")}
          <div className="speaker-block-talk-info d-flex flex-column">
              <h3 className="font-weight-bold mt-2">
                  {speaker.talkTitle}
              </h3>
            <h4 className="mb-0 speaker-name">
              <span className="highlight">{speaker.firstName}</span>{" "}
              {speaker.lastName}
            </h4>
            <div className="mt-0">{speaker.jobTitle}</div>
            <div className="mobile-room-track d-sm-none mt-4">
              <span className="font-weight-bold">ROOM</span>
              <span className="font-weight-bold ml-1">{speaker.track}</span>
            </div>
          </div>
          <div className="filler"></div>
          {getImage("d-block d-sm-none ml-2")}
          <div className="speaker-block-track align-items-center flex-column d-none d-sm-flex">
            <h5 className="mb-0 w-100 d-none d-sm-block room">ROOM</h5>
            <h6 className="mb-0 w-100 d-sm-none room">ROOM</h6>
            <h4 className="d-none d-sm-block">{speaker.track}</h4>
            <h5 className="d-sm-none">{speaker.track}</h5>
          </div>
        </div>
      </>
    );
};

export default ScheduleSpeakerBlock;
